const MultiChoice = ({ question_options, value, setValue, setError, answerOptionIds, setAnswerOptionIds }) => {
    console.log("question_options", question_options)

    const handleChange = (id, score) => {
        setError('')
        
        if(answerOptionIds !== null){
            let tempIds = answerOptionIds.split(",")
            if(tempIds.includes(id.toString())){
                tempIds = tempIds.filter((tempId) => {
                    return tempId != id
                });
                setAnswerOptionIds(tempIds.join(","))
                setValue(parseInt(value) - parseInt(score))
            }
            else{
                setAnswerOptionIds(answerOptionIds+","+id)
                setValue(parseInt(value) + parseInt(score))
            }
                  
        }
        else{
            setAnswerOptionIds(id.toString())
            setValue(parseInt(score))
        }        
    };


    return (
        <div className="col-md-3 text-end">
            <div className="col-md-3 text-end">
                <div className="button-wrap">
                    <div className="d-flex align-items-center ss-choice">
                        {
                            question_options.length > 0 && question_options.map((question_option) => (
                                <>                                    
                                    <input className="question-option hidden radio-label" type="checkbox" id={`${question_option.optionName}`} name="singleChoice" value={`${question_option.optionScore}`}  checked={answerOptionIds && answerOptionIds.includes(question_option.id) ? true : false}/>
                                    <label className="button-label" htmlFor={`${question_option.optionName}`} onClick={() => handleChange(question_option.id, question_option.optionScore)}>
                                        <span>{question_option.optionName}</span>
                                    </label>
                                </>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MultiChoice;