// ** React Imports
import { useState } from 'react'

// ** MUI Imports
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TablePagination from '@mui/material/TablePagination'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LoginIcon from '@mui/icons-material/Login';
import { styled } from '@mui/material/styles'
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useLocation } from 'react-router-dom'
import BlockIcon from '@mui/icons-material/Block';
// import SendIcon from '@mui/icons-material/Send';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';

import { Link } from 'react-router-dom'
import SendIcon from '@mui/icons-material/Send';
// import Link from '@mui/material/Link'

import CircularProgress from '@mui/material/CircularProgress';
import { Box } from "@mui/material";
import AssessmentIcon from '@mui/icons-material/Assessment';
import VisibilityIcon from '@mui/icons-material/Visibility';
var moment = require('moment');

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const TableStickyHeader = ({columns, rows, setEditId, setPopupType, setShow, handleEdit, handleDelete, handleStatus,handleReminder,handleUserLogin,handleEndSession,isLoading, handleViewReport, handleGenerateReport, showRatersList, ...rest}) => {
  // ** States
  const authUser = localStorage.getItem('authUser');
  const user = JSON.parse(authUser);

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }  
 
  // const handleInvalidStatus = () => {
  //   Swal.fire('Please subscribe to a plan to activate the company!')
  // }
  const location = useLocation();
  // console.log(location.pathname);
  const current_path = location.pathname;

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label='sticky table'>
        {columns.length > 0 ? 
        <>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell key={column.id} align={column.align} size='small' sx={{ minWidth: column.minWidth }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {rows.length > 0 ? 
          <>
          { isLoading ? 
          <TableBody>
            <TableRow>
              <TableCell colSpan={columns.length}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <CircularProgress thickness={5} size={45}/>
                </Box>
              </TableCell>
            </TableRow>
          </TableBody> 
          :
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
              return (
                <TableRow hover role='checkbox' tabIndex={-1} key={row.code}>
                  {columns.map(column => {
                    const value = row[column.id]

                    return (
                      <TableCell key={column.id} align={column.align} size='small'>
                        
                        {
                          column.id === 'action' ?                          
                            
                          user.id === row.userId || current_path==='/subjects' || current_path==='/plans' || current_path==='/companies'   || (current_path === '/settings_survey' && user.id === row.createdBy) ? 
                          (
                            current_path === '/sessions' ? 
                            (
                              <>{row.endDate === null ? 
                                <>
                                <Link to={"/invite-user/"+row.id} > 
                                  <Tooltip title="Invite Users" size='small' className='ss_edit' sx={{ marginRight: 2 }}> 
                                    <IconButton size='small'><EditIcon /></IconButton> 
                                  </Tooltip>
                                </Link>

                                <Link to={"/reminder/"+row.id} > 
                                  <Tooltip title="Reminder send" size='small' className='ss_view' sx={{ marginRight: 2 }}> 
                                    <IconButton size='small'><ForwardToInboxIcon /></IconButton> 
                                  </Tooltip>
                                </Link>
                                
                                <Tooltip onClick={() => handleEndSession(row.id)} title="End Session" style={{cursor:'pointer'}} sx={{ marginRight: 1.5 }}>
                                    <BlockIcon />
                                </Tooltip>

                                </>
                                :""
                              }
                                
                              </> 
                            ):
                              <>  
                              {/* {current_path !=='/companies' ?                            */}
                              <Tooltip onClick={() => handleEdit(row.id)} title="Edit" size='small' className='ss_edit' sx={{ marginRight: 1.5 }}>
                                <IconButton >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip> 
                              {/* :""} */}
                              {current_path !== '/plans' ?
                                <Tooltip onClick={() => handleDelete(row.id)} title="Delete" size='small' className='ss_delete'>
                                  <IconButton>
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip> : 
                              "" }
                            </>                              
                          ) :                           
                          ''
                          :
                          column.id === 'noOfRaters' ?
                            <span style={{cursor: 'pointer', textDecoration: 'underline', fontWeight: 'bold'}} onClick={() => showRatersList(row.subject.assessment_raters)}>{row.noOfRaters}</span>
                          :
                          column.id === 'status' ? 
                          ( 
                            current_path.includes('reminder') ? 
                              (row.status === 1 ? <span className="badge rounded-pill bg-success">Completed</span> : <span className="badge rounded-pill bg-warning">Pending</span>)
                            : 
                            current_path.includes('assessments') ?
                              (row.status === 'Completed' ? <span className="badge rounded-pill bg-success">Completed</span> : <span className="badge rounded-pill bg-warning">Pending</span>)
                            :
                            // current_path.includes('companies') ?
                            //   <IOSSwitch size='small' onChange={(e) => row.user_plan.length === 0 ? handleInvalidStatus() : handleStatus(e, row.id)} defaultChecked={row.status === '1' ? true : false}/>
                            //   :
                            <IOSSwitch size='small' onChange={(e) => handleStatus(e, row.id)} defaultChecked={row.status === '1' ? true : false}/>
                          )

                          :

                          column === 'manager' ?
                          value !== null && typeof value === 'object' ? value.name : ''
                          :

                          column.id === 'reminder'? 
                            // <TextField fullWidth size='small' select
                            //     ListboxProps={{
                            //       sx: { fontSize: 13},
                            //     }}
                            //     sx={{
                            //       '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                            //         fontSize: 13,
                            //         fontFamily: 'Poppins', // Add your desired font family
                            //         fontWeight: '500', // Add your desired font weight
                            //       },
                            //     }}
                            //     label='Please Select'
                            //     onChange={(e) => handleReminder(e, row.userId,row.manager_name.id,row.user_name.name)}
                            //     >
                            //       <MenuItem selected hidden>Please Select</MenuItem>
                            //       <MenuItem  value="user">{row.user_name ? row.user_name.name  : ''}</MenuItem>
                            //       <MenuItem  value="manager">{row.manager_name?row.manager_name.name:''}</MenuItem>
                            //   </TextField>
                            row.status === 1 ? '' : 
                            // <Button style={{color:'White'}} size='small' title="Send Reminder" variant='contained' onClick={(e) => handleReminder(e, row.userId,row.manager_name.id,row.user_name.name)} > <SendIcon /> </Button>
                            
                            <Tooltip style={{color:'White'}} size='small' title={"Send Reminder to "+row.user_name.name} variant='contained' onClick={(e) => handleReminder(e, row.userId,row.manager_name.id,row.user_name.name)} className='ss_view'>
                              <IconButton>
                              <SendIcon />
                              </IconButton>
                            </Tooltip>
                              
                          :
                          column.id === 'login' ? 
                          <Tooltip size='small' onClick={() => handleUserLogin(row.email)} title="Login" className='ss_view'>
                            <IconButton>
                              <LoginIcon />
                            </IconButton>
                          </Tooltip>
                          :

                          column.id === 'selfStatus' ?
                          row.selfStatus === 'Done' ?
                            <span className="badge rounded-pill bg-success">Completed</span>
                            :
                            <span className="badge rounded-pill bg-warning">Pending</span>
                        
                          : 

                          column.id === 'isReportGenerated' ? 
                              row.isReportGenerated === '1'  ?
                              <Tooltip size='small' onClick={() => handleViewReport(row.subjectId)} title="View Reoprt" className='btn-view-gen-report'>
                                <IconButton>
                                  <VisibilityIcon /> <span style={{fontSize: "13px", marginLeft: "5px"}}>View Report</span> 
                                </IconButton>
                              </Tooltip>
                              :
                              row.status === 'Completed' ? 
                              <Tooltip size='small' onClick={() => handleGenerateReport(row.id)} title="Generate Reoprt" className='btn-view-gen-report'>
                                <IconButton>
                                  <AssessmentIcon /> <span style={{fontSize: "13px", marginLeft: "5px"}}>Generate Report</span> 
                                </IconButton>
                              </Tooltip>
                              : ''
                          :

                          column.id === 'surveyName' ? 
                         row.survey && row.survey.name ? row.survey.name:""
                          : 

                          // column.id === 'selfEndedAt' ? 
                          //   row.user_name && row.user_name.user_self_evaluations.length>0 ? row.user_name.user_self_evaluations[0].selfEndedAt:'-'
                          // :

                          column.id === 'updatedAt' ? 
                            row.status === 1 ? row.updatedAt : '-'
                          : 

                          // column.id === 'surveyName' ? 
                          // (value !== null && (typeof value === 'object' || typeof value === 'boolean')) ?  row.survey.name: ''
                          // :

                          column.id === 'createdAt' ? 
                          moment(row.createdAt).format('MM/DD/YYYY')
                          : 
                          

                          value !== null && typeof value === 'object' ? Array.isArray(value) ? value.length > 0 && value[0].plan ? value[0].plan.name : '' : value.name : value
                            
                        } 
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableBody>
          }
          </>:""}
          </>:""
        }
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component='div'
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}

export default TableStickyHeader
