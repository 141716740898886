// ** React Imports
import React, { useEffect, useState } from "react";
import { Fragment } from 'react'
// ** MUI Components
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import { styled } from '@mui/material/styles'
import MuiCard from '@mui/material/Card'
import MuiFormControlLabel from '@mui/material/FormControlLabel'
import { Link, useNavigate } from 'react-router-dom'
import BlankLayout from '../Layout/BlankLayout'
import FooterIllustrationsV1 from './FooterIllustration'
import { useFormik } from 'formik';
import * as yup from 'yup';
import { FormHelperText } from '@mui/material'
import axios from "axios";
import Swal from 'sweetalert2';

import PrivacyPolicies from './PrivacyPolicies';
// ** Styled Components
const Card = styled(MuiCard)(({ theme }) => ({    
    [theme.breakpoints.up('sm')]: { width: '28rem' }
}))

const LinkStyled = styled('a')(({ theme }) => ({
    fontSize: '0.875rem',
    textDecoration: 'none',
    color: theme.palette.primary.main
}))

const FormControlLabel = styled(MuiFormControlLabel)(({ theme }) => ({
    '& .MuiFormControlLabel-label': {
    fontSize: '0.875rem',
    color: theme.palette.text.secondary
    }
}))

const validationSchema = yup.object({
    name: yup
      .string('Enter your name')
      .required('Name is required'),
    companyName: yup
      .string('Enter your companyName')
      .required('Company Name is required'),
    email: yup
      .string('Enter your email')
      .email('Enter a valid email')
      .required('Email is required'),
    password: yup
      .string('Enter your password')
      .min(8, 'Password should be of minimum 8 characters length')
      .required('Password is required'),
    agree: yup
        .boolean()
        .oneOf([true], "You must agree privacy policy & terms")
});

export default function Signup() {

    const [show, setShow] = useState(false);

    const navigate = useNavigate();
    const initialValues = {
        companyName: '',
        name: '',
        email: '',
        password: '',
        agree: false
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values, { setSubmitting }) => {
            handleSubmit(values, setSubmitting);            
        }
    });

    const handlePrivacyPolicies = () => {
        console.log("Opening Privacy Policies");
        setShow(true);
    };

    const handleClose = () => {
        console.log("Closing Privacy Policies");
        setShow(false);
    }
    const handleSubmit = (values, setSubmitting) => {
        // console.log(values);
        // return
        axios.post('/api/auth/signup', values).then((res) =>{
            setSubmitting(false)
            if(res.data.success){
                Swal.fire(res.data.message, '', 'success').then(result => {
                    navigate("/verification?id="+res.data.verification_token)                 
                })                
            }
            else{
                Swal.fire(res.data.message, '', 'info')
            }
        }).catch((error) => {
            Swal.fire(error.message, '', 'info')        
        });
    }

    return (
        
        <BlankLayout>
            <Box className='content-center'>
                <Card sx={{ zIndex: 1 }}>
                    <CardContent sx={{ padding: theme => `${theme.spacing(12, 9, 7)} !important` }}>
                    <Box sx={{ mb: 8, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Link to="/"><img src="./images/logo.png" alt="..." width="150"/></Link>
                    </Box>
                    <Box sx={{ mb: 6 }}>
                        <Typography variant='h5' sx={{ fontWeight: 600, marginBottom: 1.5 }}>
                        Welcome to People Assay!
                        </Typography>
                        {/* <Typography variant='body2'>Make your app management easy and fun!</Typography> */}
                    </Box>
                    <form autoComplete="off" onSubmit={formik.handleSubmit}>
                        <TextField autoFocus fullWidth 
                            ListboxProps={{
                                sx: { fontSize: 13,
                                    fontFamily: 'Poppins' },
                            }}
                            sx={{
                                '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                    fontSize: 13,
                                    fontFamily: 'Poppins', // Add your desired font family
                                    fontWeight: '500', // Add your desired font weight
                                },
                                marginBottom: 4
                            }}
                            id='name' 
                            label='Contact Person Name'
                            value={formik.values.name} 
                            onChange={formik.handleChange} 
                            onBlur={formik.handleBlur}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                        />
                        <TextField fullWidth 
                            id='companyName' 
                            label='Company Name' 
                            ListboxProps={{
                                sx: { fontSize: 13,
                                    fontFamily: 'Poppins' },
                            }}
                            sx={{
                                '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                    fontSize: 13,
                                    fontFamily: 'Poppins', // Add your desired font family
                                    fontWeight: '500', // Add your desired font weight
                                },
                                marginBottom: 4
                            }}
                            value={formik.values.companyName} 
                            onChange={formik.handleChange} 
                            onBlur={formik.handleBlur}
                            error={formik.touched.companyName && Boolean(formik.errors.companyName)}
                            helperText={formik.touched.companyName && formik.errors.companyName}
                        />
                        <TextField fullWidth 
                            id='email' 
                            label='Email' 
                            ListboxProps={{
                                sx: { fontSize: 13,
                                    fontFamily: 'Poppins' },
                            }}
                            sx={{
                                '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                    fontSize: 13,
                                    fontFamily: 'Poppins', // Add your desired font family
                                    fontWeight: '500', // Add your desired font weight
                                },
                                marginBottom: 4
                            }}
                            value={formik.values.email} 
                            onChange={formik.handleChange} onBlur={formik.handleBlur}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                        />
                        <TextField fullWidth 
                            id='password' 
                            label='Password'
                            type='password'
                            ListboxProps={{
                                sx: { fontSize: 13,
                                    fontFamily: 'Poppins' },
                            }}
                            sx={{
                                '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                    fontSize: 13,
                                    fontFamily: 'Poppins', // Add your desired font family
                                    fontWeight: '500', // Add your desired font weight
                                },
                                marginBottom: 4
                            }}
                            value={formik.values.password} 
                            onChange={formik.handleChange} onBlur={formik.handleBlur}
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            helperText={formik.touched.password && formik.errors.password}
                        />
                        
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.agree}
                                    id='agree'
                                    onChange={formik.handleChange}
                                />
                            }
                            label={
                                <Fragment>
                                <span>I agree to </span>
                                <LinkStyled onClick={handlePrivacyPolicies}>privacy policy & terms</LinkStyled>
                                </Fragment>
                            }
                        />
                        {/* Privacy Policies Modal */}
                        <PrivacyPolicies show={show} handleClose={handleClose} />

                        <FormHelperText error>
                        {formik.touched.agree && formik.errors.agree ? formik.errors.agree  : " "}
                            {/* {formik.touched.agree ? formik.errors.agree : " "} */}
                            {/* {formik.touched.agree && <p className='text-danger'>{formik.errors.agree}</p>} */}
                        </FormHelperText>
                        
                        <Button fullWidth size='large' type='submit' variant='contained' disabled={formik.isSubmitting} sx={{ marginTop: 3, marginBottom: 5 }}>
                            Sign up
                        </Button>
                        <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'center' }}>
                            <Typography variant='body2' sx={{ marginRight: 2 }}>
                                Already have an account?
                            </Typography>
                            <Typography variant='body2'>
                                <Link to='/login'>Sign in instead</Link>
                            </Typography>
                        </Box>                        
                    </form>
                    </CardContent>
                </Card>
                <FooterIllustrationsV1 />
            </Box>
        </BlankLayout>
        
        
        
    )
}