import { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { IconButton, Tooltip } from "@mui/material";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import Stack from '@mui/material/Stack';
import axios from "axios";
import Swal from 'sweetalert2'
import CancelIcon from '@mui/icons-material/Cancel';

export const RatersList = ({show, setShow, raters, setRaters, ...rest}) => {
    const [isSent,setIsSent] = useState(null)
    const handleClose = () => setShow(false);
    
    useEffect(() => {
        if (isSent !== null) {
            const timer = setTimeout(() => {
                setIsSent(null);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [isSent]);

    const sendReminder = (raterId) => {
        console.log("raterId==",raterId)
        axios.post(`api/sendReminder`, {raterId: raterId}).then(res => {
            if(res.data.success){
                setIsSent(raterId)            
            } 
            else{
                Swal.fire(res.data.message, '', 'error')
            }
        }).catch((error) => {
            console.log("error==",error)
        });
    }


    return (
        <Modal show={show} size="lg" onHide={handleClose}>
            <Modal.Header>
                <h5 className="modal-title fs-14 mb-0">Rater List</h5>
                <CancelIcon style={{cursor: 'pointer'}} onClick={handleClose}/>
            </Modal.Header>
            <Modal.Body>                                               
                <div className="row">
                    <div className="col-md-12">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr key={'rater_tab_header'}>
                                        <th className='fw-normal' scope="col">S. No.</th>
                                        <th className='fw-normal' scope="col">Name</th>
                                        <th className='fw-normal' scope="col">Email</th>
                                        <th className='fw-normal' scope="col">Status</th>
                                        {/* <th scope="col">Rater Group</th> */}
                                        <th className='fw-normal' scope="col">Send Reminder</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                raters.map((rater, index) => (
                                    <tr key={'rater_tbl_'+index}>
                                        <th className='fw-normal' scope="row">{index+1}</th>
                                        <td>{rater.name}</td>
                                        <td>{rater.email}</td>
                                        <td><Stack spacing={1}>                   
                                                <Stack direction="row" spacing={1}>
                                                {
                                                    rater.status === 'Pending' ? 
                                                    <span className="badge rounded-pill bg-warning">Pending</span>
                                                    :
                                                    <span className="badge rounded-pill bg-success">Completed</span>
                                                }   
                                                </Stack>
                                            </Stack>
                                        </td>
                                        {/* <td>{findNameById(rater.raterGroup, groups)}</td> */}
                                        <td>
                                            {rater.status === 'Pending' ? 
                                            <>
                                            <Tooltip title="Reminder send" size='small' className='ss_view' sx={{ marginRight: 2 }} onClick={() => sendReminder(rater.id)}> 
                                                <IconButton size='small'><ForwardToInboxIcon /></IconButton> 
                                            </Tooltip>
                                            <span className="text-success" style={{display:isSent===rater.id?'block':'none'}}>&nbsp; Sent!</span>
                                            </>
                                            :""}
                                        </td> 
                                    </tr>
                                )) 
                                }                                                            
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>  
            </Modal.Body>
        </Modal>
    )
}