import UserLayout from '../Layout/UserLayout'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import TableStickyHeader from '../Common/TableStickyHeader'
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import axios from 'axios'
import Swal from 'sweetalert2'
import { RatersList } from './RatersList'

export default function List() {
    const authUser = localStorage.getItem('authUser');
    const user = JSON.parse(authUser);
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [show, setShow] = useState(false);
    const [raters, setRaters] = useState([]);

    useEffect(() => {
        if(user.role !== 'Admin'){
            navigate('/dashboard')
        }
        getList();
    }, [])

    const getList = async() => {
        await axios.get(`/api/assessments`)
        .then(function (response) {
            let result = response.data;
            if(result.success){
                setUsers(result.data)
            }
        })
    }

    const columns = [
        { id: 'subject', label: 'Subject' },
        { id: 'survey', label: 'Survey' },        
        { id: 'noOfRaters', label: 'No of Raters' },
        { id: 'createdAt', label: 'Sent Date' },
        { id: 'selfStatus', label: 'Self Status' },
        { id: 'status', label: 'Status' },
        // { id: 'action', label: 'Send Reminders to All'},
        { id: 'isReportGenerated', label: 'Action'}
    ]

    const handleViewReport = (id) => {
        navigate('/reports?id='+id)
    }

    const handleGenerateReport = (id) => {
        Swal.fire({
            title: 'Are you sure you want to generate report?',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, generate it!'
          }).then((result) => {
            if (result.isConfirmed) { 
                axios.put(`/api/assessment/generate_report/${id}`).then((res) =>{
                    if(res.data.success){                             
                        Swal.fire(res.data.message, '', 'success')
                        getList();
                    } 
                }).catch((error) => {
                    console.log(error);                     
                    Swal.fire(error.message, '', 'info')           
                });
            } else if (result.isDenied) {
              Swal.fire('Cancelled!', '', 'info')
            }
        }) 
    }

    const showRatersList = (ratersList) => {
        setRaters(ratersList);
        // setAssessmentSentAt(assessmentSentAt);
        setShow(true);
    }

    return (
        <UserLayout>
            <div className="row">
                <div className='col-md-4 col-6 mb-3'>
                    <h1>Assessments</h1>
                </div>
                <div className='col-md-8 col-12 text-md-end mb-3'>
                    <Link to="/send_assessment"><Button size='small' variant='contained'>Create Assessment</Button></Link>
                </div>
            </div>
            <Grid container spacing={6}>            
                <Grid item xs={12}>
                    <Card>
                        <TableStickyHeader columns={columns} rows={users} handleViewReport={handleViewReport} handleGenerateReport={handleGenerateReport} showRatersList={showRatersList}/>
                    </Card>
                </Grid>           
            </Grid>
            <RatersList show={show} setShow={setShow} raters={raters} setRaters={setRaters}/>
        </UserLayout>
    )
}