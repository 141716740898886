import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Button from '@mui/material/Button'
import CancelIcon from '@mui/icons-material/Cancel';

// ** MUI Imports
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import Swal from 'sweetalert2'
import { useFormik } from 'formik';
import * as yup from 'yup';


export const PrivacyPolicies = ({ show, handleClose }) => {

    // const formik = useFormik({
    //     initialValues: initialValues,
    //     validationSchema: validationSchema,
    //     onSubmit: values => {
    //         handleSubmit(values);
    //     }
    // });

    return (
        <Modal show={show} onHide={handleClose} dialogClassName="modal-lg">
            <form autoComplete="off">
                <Modal.Header>
                    <h5 className="modal-title fs-14 mb-0">Privacy Policies</h5>
                    <CancelIcon style={{ cursor: 'pointer' }} onClick={handleClose} />
                </Modal.Header>
                <Modal.Body>
                    <ol className='privacyPolicyList'>
                        <li>Introduction</li>
                        <p>Welcome to PeopleAssay. We value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website [peopleassay.com] and use our 360 survey services. Please read this Privacy Policy carefully. By using our website and services, you agree to the terms outlined below.</p>
                        <li>Information We Collect</li>
                        <ol>
                            <li>Personal Information</li>
                            <p>We may collect personal information that you provide to us directly, such as your name, email address, phone number, and job title, when you:</p>
                            <ul>
                                <li>Sign up for an account</li>
                                <li>Participate in surveys or assessments</li>
                                <li>Contact us for support or inquiries</li>
                            </ul>
                            <li>Usage Data</li>
                            <p>We may collect information about how you use our website and services, including your IP address, browser type, operating system, and browsing behavior. This information helps us understand how our services are used and improve your experience.</p>
                            <li>Cookies and Tracking Technologies</li>
                            <p>We use cookies and similar tracking technologies to enhance your experience on our website. Cookies are small data files stored on your device that help us recognize you and remember your preferences. You can control the use of cookies through your browser settings.</p>
                        </ol>
                        <li>How We Use Your Information</li>
                        <p>We use your personal information for various purposes, including:</p>
                        <ul>
                            <li>Providing and improving our 360 survey services</li>
                            <li>Sending you updates, newsletters, and promotional materials (with your consent)</li>
                            <li>Responding to your inquiries and providing customer support</li>
                            <li>Analyzing usage data to enhance our website and services</li>
                            <li>Complying with legal obligations and protecting our rights</li>
                        </ul>
                        <li>How We Share Your Information</li>
                        <p>We may share your information in the following circumstances:</p>
                        <ul>
                            <li>With Service Providers</li>
                            <p>We may share your information with third-party service providers who assist us in operating our website and providing our services, such as payment processors and data storage providers. These providers are bound by confidentiality agreements and are not authorized to use your information for any other purpose.</p>
                            <li>For Legal Reasons</li>
                            <p>We may disclose your information if required by law, or in response to a legal request or investigation.</p>
                            <li>Business Transfers</li>
                            <p>In the event of a merger, acquisition, or sale of assets, we may transfer your information to the acquiring entity.</p>
                        </ul>
                        <li>Data Security</li>
                        <p>We implement reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure. While we strive to protect your information, we cannot guarantee its absolute security.</p>
                        <li>Your Choices</li>
                        <p>You have the right to access, correct, or delete your personal information. You may also object to the processing of your data or withdraw your consent at any time. To exercise these rights, please contact us using the information provided below.</p>
                        <li>Links to Other Websites</li>
                        <p>Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these external sites. We encourage you to review their privacy policies before providing any personal information.</p>
                        <li>Changes to This Privacy Policy</li>
                        <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. We encourage you to review this Privacy Policy periodically to stay informed about our practices.</p>
                        <li>Contact Us</li>
                        <p>If you have any questions or concerns about this Privacy Policy or our practices, please contact us at:</p>
                        <p>PeopleAssay</p>
                    </ol>
                </Modal.Body>
                <Modal.Footer>
                    <Button type='reset' sx={{ marginRight: 3.5 }} size='small' variant='outlined' onClick={handleClose}>
                        Close
                    </Button>
                    {/* <Button type="submit" variant='contained' size='small'>
                        {type == 'Add' ? 'Add' : 'Update'} 
                    </Button> */}
                </Modal.Footer>
            </form>
        </Modal>
    );
}

PrivacyPolicies.propTypes = {
    setShow: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired
};

export default PrivacyPolicies;
