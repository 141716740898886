import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import RatingQuestions from "./RatingQuestions";
import Typography from "@mui/material/Typography";
import SingleChoice from "./SingleChoice";
import { TextField } from '@mui/material';
import MultiChoice from "./MultiChoice";

export default function Assessment() {
    const { surveyId, subjectId, raterId } = useParams();
    const [questions, setQuestions] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState();
    const [currentIndex, setCurrentIndex] = useState(0);
    const [surveyRatings, setSurveyRatings] = useState({});
    const [labels, setLabels] = useState({});
    // const [filledAnswers, setFilledAnswers] = useState([]);
    const [value, setValue] = useState('');
    const [error, setError] = useState('');
    // const [totalAnswered, setTotalAnswered] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [answerOptionIds, setAnswerOptionIds] = useState(null);

    useEffect(() => {
        getList()
        getAnswers()
    }, [surveyId])

    const getList = async () => {
        await axios.get(`/api/assessment/questions/${surveyId}/${subjectId}`).then(function (response) {
            let result = response.data;
            if (result.success) {
                console.log("result", result.data)
                setQuestions(result.data.questions)
                setSurveyRatings(result.data.surveyRatingSetting)
                let labels = {}
                result.data.surveyRatingSetting.setting_labels.forEach((item, index) => {
                    labels[index + 1] = item.label;
                });
                setLabels(labels)
            }
        });
        setIsLoading(false)
    };

    const getAnswers = async () => {
        let url = `/api/assessment/answers/${subjectId}`
        if (raterId !== '') {
            url = `/api/assessment/answers/${subjectId}/${raterId}`
        }
        await axios.get(url).then(function (response) {
            let result = response.data;
            if (result.success) {
                console.log("result", result.data)
                setAnswers(result.data)
                // setFilledAnswers(result.data)
                // setTotalAnswered(result.data.length)
            }
        });
    }

    useEffect(()=>{        
        setCurrentQuestion(questions[currentIndex])
        if(answers && answers[currentIndex]){
            setValue(answers[currentIndex].answer)
            setAnswerOptionIds(answers[currentIndex].answerOptionIds)
            // setCharacterCount(answers[currentIndex].answer.length)
        }
    },[currentIndex, questions, answers])

    const handleBack = () => {
        setError('')
        setCurrentIndex(currentIndex >= 1 ? currentIndex - 1 : currentIndex)
        getAnswers()
    }

    const handleNext = async (isSubmit = '') => {
        setError('')
        const data = {
            subjectId: subjectId,
            questionId: currentQuestion && currentQuestion.questionId,
            answer: value,
            answerOptionIds: answerOptionIds,
            answerBy: raterId && raterId != '' ? raterId : subjectId,
            isSelf: raterId && raterId != '' ? '0' : '1',
            submitted: isSubmit !== '' ? '1' : ''
        }
        console.log("postdata", data)
        console.log("data.answer.toString()", data.answer.toString())
        if (data.answer.toString() === '' || (currentQuestion.question.question_type.id === 4 && answerOptionIds.trim() == '')) {
            console.log(currentQuestion)
            if(currentQuestion && (currentQuestion.question.question_type.id === 1 || currentQuestion.question.question_type.id === 3)){
                setError('Please select one of the options above')
            }
            else if(currentQuestion && currentQuestion.question.question_type.id === 4){
                setError('Please select at least one of the options above')
            }
            else{
                setError('Please fill the answer')
            }
            return false;
        }
        console.log("postdata", data)
        await axios.post(`/api/assessment/answers`, data).then(async function (response) {
            let result = response.data;
            setValue('');
            setAnswerOptionIds(null);
            if (result.success) {
                if (isSubmit === '1') {
                    getAnswers()
                }
                else {
                    setCurrentIndex(currentIndex + 1)
                }
            }
        })
    }

    return (
        <section className="flex-column d-flex align-items-center justify-content-center min-vh-100">
            <div className="container">
                <div className="text-center">
                    <img className="logoSetting" src={process.env.REACT_APP_PUBLIC_URL + "images/logo.png"} alt="People Assay Logo" />
                </div>
                <div className="row d-flex justify-content-center">
                    <div className="col-md-6">
                        <div className="card-body">
                            {
                                isLoading === true ? '' :

                                    questions.length === answers.length ?
                                        <div className="success-page">
                                            <h6>Assessment Completed</h6>
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                            <img className="success-img" src="/images/success-img.webp"></img>
                                        </div>
                                        :
                                        <>
                                            <div className="ss_svg">
                                                <div className="d-flex justify-content-between align-items-center ">
                                                    <h5 className="fw-semibold ">Question</h5>
                                                    <h4>{currentIndex + 1}/{questions.length}</h4>
                                                </div>
                                                <h5 className="fw-normal mb-3">{currentQuestion && currentQuestion.question.name}</h5>
                                                <div className="">
                                                    {
                                                        currentQuestion && currentQuestion.question.question_type.id === 1 ?
                                                            <RatingQuestions
                                                                value={value}
                                                                setValue={setValue}
                                                                surveyRatings={surveyRatings}
                                                                labels={labels}
                                                                setError={setError}
                                                            />
                                                            : currentQuestion && currentQuestion.question.question_type.id === 2 ?
                                                                // <input />
                                                                <TextField fullWidth
                                                                    sx={{
                                                                        '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                                                            fontSize: 13,
                                                                            fontFamily: 'Poppins', // Add your desired font family
                                                                            fontWeight: '500', // Add your desired font weight
                                                                        },
                                                                    }}
                                                                    size='small'
                                                                    placeholder='Enter your feedback'
                                                                    value={value}
                                                                    onChange={(e) => {
                                                                        setValue(e.target.value);
                                                                        setError('')
                                                                        // setCharacterCount(e.target.value.length)
                                                                    }}
                                                                />
                                                                : currentQuestion && currentQuestion.question.question_type.id === 3 ?
                                                                    <SingleChoice 
                                                                        question_options={currentQuestion.question.question_options} 
                                                                        value={value} 
                                                                        setValue={setValue} 
                                                                        setError={setError}
                                                                        answerOptionIds={answerOptionIds}
                                                                        setAnswerOptionIds={setAnswerOptionIds}
                                                                    />
                                                                    : currentQuestion && currentQuestion.question.question_type.id === 4 ?
                                                                    <MultiChoice 
                                                                        question_options={currentQuestion.question.question_options} 
                                                                        value={value} 
                                                                        setValue={setValue} 
                                                                        setError={setError}
                                                                        answerOptionIds={answerOptionIds}
                                                                        setAnswerOptionIds={setAnswerOptionIds}
                                                                    /> : ''
                                                    }
                                                </div>
                                            </div>
                                            <span className="text-danger">{error ? error : ''}</span>
                                            <hr />
                                            <div className="d-flex align-items-center justify-content-end">
                                                {
                                                    currentIndex > 0 ?
                                                        <button className="btn btn-secondary" onClick={handleBack}>Back</button>
                                                        : ""
                                                }
                                                {
                                                    currentIndex + 1 === questions.length ?
                                                        <button className="ms-3 btn btn-theme" onClick={() => handleNext('1')}>Submit</button>
                                                        :
                                                        <button className="ms-3 btn btn-theme" onClick={() => handleNext()}>Next</button>
                                                }
                                            </div>
                                        </>

                            }

                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}