// ** React Imports
import { useState, useEffect } from "react";
import axios from "axios";

// ** MUI Imports
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";
import SquareIcon from "@mui/icons-material/Square";
import CircleIcon from "@mui/icons-material/Circle";

const RatingQuestions = ({ value, setValue, surveyRatings, labels, setError }) => {

    const [hover, setHover] = useState(-1)

    const RatingTotal = surveyRatings ? surveyRatings.rating : 5;

    const getLabelText = (value) => {
        return `${value} ${surveyRatings.ratingIcon}${value !== 1 ? "s" : ""}, ${labels[value]}`;
    };

    const handleRatingChange = (newValue) => {   
        setError('')
        setValue(newValue);
    };
    
    const selectEmptyIcon = () => {
        switch (surveyRatings.ratingIcon) {
            case "Square":
                return <SquareIcon style={{ opacity: 0.55 }} fontSize="inherit" />;
            case "Circle":
                return <CircleIcon style={{ opacity: 0.55 }} fontSize="inherit" />;
            case "Star":
                return <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />;
            default:
                return '';
        }
    }
    const selectRatingIcon = () => {
        switch (surveyRatings.ratingIcon) {
            case "Square":
                return <SquareIcon />;
            case "Circle":
                return <CircleIcon />;
            case "Star":
                return <StarIcon />;
            default:
                return '';
        }
    }

    return (
        <div>
            <Rating
                name={`rating-feedback`}
                value={value}
                precision={1}
                max={RatingTotal}
                getLabelText={getLabelText}
                onChange={(event, newValue) => handleRatingChange(newValue)}
                emptyIcon={selectEmptyIcon()}
                icon={selectRatingIcon()}
                title={(value) => getLabelText(value)}
                onChangeActive={(event, newHover) => {
                    setHover(newHover);
                }}
            />
            {value !== null && (
                <Box sx={{fontSize:'12px', marginLeft: '30px', textAlign:'right'}}>{labels[hover !== -1 ? hover : value]}</Box>
            )}
        </div>
    );
};

export default RatingQuestions;