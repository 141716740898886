const SingleChoice = ({ question_options, value, setValue, setError, answerOptionIds, setAnswerOptionIds }) => {
    console.log("question_options", question_options)

    const handleChange = (id, score) => {
        setError('')
        setValue(score)
        setAnswerOptionIds(id)  
    };


    return (
        <div className="col-md-3 text-end">
            <div className="col-md-3 text-end">
                <div className="button-wrap">
                    <div className="d-flex align-items-center ss-choice">
                        {
                            question_options.length > 0 && question_options.map((question_option) => (
                                <>                                    
                                    <input className="question-option hidden radio-label" type="radio" id={`${question_option.optionName}`} name="singleChoice" value={`${question_option.optionScore}`}  checked={question_option.id === parseInt(answerOptionIds) ? true : false}/>
                                    <label className="button-label" htmlFor={`${question_option.optionName}`} onClick={() => handleChange(question_option.id, question_option.optionScore)}>
                                        <span>{question_option.optionName}</span>
                                    </label>
                                </>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SingleChoice;