import UserLayout from '../Layout/UserLayout'
import Grid from '@mui/material/Grid'
import CardNavigationCenter from './componentsV2/CardNavigationCenter'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { MenuItem, TextField } from '@mui/material'
import jsPDF from 'jspdf';
import { useSearchParams } from 'react-router-dom'

export default function ReportV2() {
    const [searchParams] = useSearchParams();
    const [subjectId, setSubjectId] = useState(null);
    const [reports, setReports] = useState([]);


    useEffect(() => {
        getReports();
        if(searchParams.get('id')){
            setSubjectId(searchParams.get('id'))
        }
    }, [])

    const getReports = async () => {
        await axios.get(`/api/assessment/report`).then(function (response) {
            let result = response.data;
            if (result.success) {                
                setReports(result.data);
            } else {
                // setIsError(true);
            }
        });
    };
    
    const handleSubjectChange = (id) => {
        setSubjectId(id)
    }

    // const handleGeneratePDF = async () => {
    //     const pdf = new jsPDF();
    //     const canvas = await html2canvas(componentRef.current);
    //     pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 15, 40, 180, 160);
    //     pdf.save('generated.pdf');
    // };

    const exportPdf = () => {
        const input = document.getElementById("pdf-element");
        if (input) {
            const pdf = new jsPDF({
                orientation: 'l',
                unit: 'px',
                format: 'a4',
                compress:true,
                // putOnlyUsedFonts:true
            });
            pdf.html(input, { html2canvas: { scale: 0.57 } }).then(() => {
                pdf.save("test.pdf");
            });
        }
      };

    return (
        <UserLayout>        
            <Grid container spacing={6}>            
                <Grid item xs={12} md={2}>
                    <h1 className='mb-3'>Reports</h1>
                </Grid> 
                { reports.length > 0 ?
                    <>
                        <Grid item xs={12} md={3}>
                            <TextField fullWidth
                                ListboxProps={{
                                    sx: { fontSize: 13,
                                        fontFamily: 'Poppins' },
                                }}
                                sx={{
                                    '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                        fontSize: 13,
                                        fontFamily: 'Poppins', // Add your desired font family
                                        fontWeight: '500', // Add your desired font weight
                                    },
                                }}
                                select size='small' label='Select Subject' id='subjectId' 
                                value={subjectId} 
                                onChange={(e) => handleSubjectChange(Number(e.target.value))} 
                            >
                                { reports && reports.length > 0 ? 
                                    reports.map(report => (
                                        <MenuItem key={"user_"+report.id} value={report.subject.id} >
                                                {report.subject.name}
                                        </MenuItem>
                                    ))
                                : 
                                    <MenuItem value=''>No subject available </MenuItem>
                                }
                            </TextField>
                        </Grid>
                    </>
                :''}
                <Grid item xs={12} md={4}>
                </Grid>      
            </Grid>
              
            { 
                subjectId > 0 ?                    
                <Grid container spacing={6}>            
                    <Grid item xs={12} md={12}>
                        <CardNavigationCenter currentUserId={subjectId}/>
                    </Grid>       
                </Grid>
                : 
                <Grid container spacing={6} justifyContent="center">            
                    <Grid item xs={12} md={6}>
                        <Grid sx={{ padding: 5}}><h6 className='mt-5 fw-normal'>Select subject to view the report.</h6></Grid>
                    </Grid>       
                </Grid>   
            }
        </UserLayout>
    )
}